var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"cy-recent-events d-flex flex-column",attrs:{"outlined":"","rounded":"lg"}},'v-card',_vm.$attrs,false),[(_vm.showCardTitle)?_c('v-card-title',{staticClass:"py-2",attrs:{"data-cy":"card-title"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"space-between"}},[_c('v-col',{staticClass:"cy-recent-events__headline wrap-column",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t('recentActivity'))+" ")]),_c('v-col',{staticClass:"cy-recent-events__time-filter font-weight-bold text-no-wrap wrap-column text-center",attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$tc('forms.field.timeRangeLastDays', 7, { days: 7 }))+" ")]),_c('v-col',{staticClass:"text-right wrap-column",attrs:{"cols":"auto"}},[_c('CyMenu',{staticClass:"text-capitalize",attrs:{"max-height":"340","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('CyButton',_vm._g(_vm._b({staticClass:"ml-0 pl-2 pr-0 mr-n3 text-capitalize",attrs:{"theme":"primary","variant":"tertiary","sm":"","icon":"mdi-filter"}},'CyButton',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('severities.severity'))+": "+_vm._s(_vm.activeSeverityFilterTranslation)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"dense":""}},[_vm._v(" arrow_drop_down ")])],1)]}}],null,false,1062437793)},[_c('v-list',[_c('v-list-item-group',{attrs:{"value":_vm._.find(_vm.eventSeverityFilterOptions, { isActive: true }),"mandatory":""}},_vm._l((_vm.eventSeverityFilterOptions),function(item,index){return _c('v-list-item',{key:index,attrs:{"value":item},on:{"click":item.action}},[_c('v-list-item-title',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1)],1)],1)],1)],1):_vm._e(),(_vm.showCardTitle)?_c('v-divider'):_vm._e(),_c('v-card-text',{class:[
      'cy-recent-events__timeline-viewport cy-scrollbars--always-show d-flex justify-center height-100 flex-grow-1 py-0',
      { 'no-card-title': !_vm.showCardTitle } ]},[(_vm.$attrs.loading)?_c('v-progress-circular',{staticClass:"my-10",attrs:{"indeterminate":"","color":"secondary","data-cy":"loading-spinner"}}):(!_vm.events.length)?_c('div',{staticClass:"cy-event-empty-state text-center py-6",attrs:{"data-cy":"empty-state"}},[_c('div',{staticClass:"cy-event-empty-state__heading h5"},[_vm._v(" "+_vm._s(_vm.$t('events.noEventsFound'))+" ")]),_c('div',{staticClass:"text-body mt-2"},[_vm._v(" "+_vm._s(_vm.$t('events.tryDifferent'))+" ")])]):_c('CyEventsTimeline',{staticClass:"width-100",attrs:{"events":_vm.events,"options":_vm.eventProps,"lazy-options":_vm.$static.timelineItemLazyOptions,"show-tags":false,"hide-default-footer":"","iterator-class":"py-2","data-cy":"events-timeline"},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
    var _obj;

    var tag = ref.tag;return [_c('router-link',{attrs:{"to":{
            name: 'events',
            params: { orgCanonical: _vm.orgCanonical },
            query: ( _obj = {}, _obj[((tag.key) + "[in]")] = tag.value, _obj ),
          },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var navigate = ref.navigate;
          var href = ref.href;
return [_c('CyTag',{attrs:{"variant":tag.variant,"label":tag.key,"small":"","href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(tag.content)+" ")])]}}],null,true)})]}}])})],1),_c('v-divider'),(_vm.allEventsRoute)?_c('v-card-title',{staticClass:"cy-recent-events__footer pa-0"},[_c('router-link',{staticClass:"width-100 d-flex justify-space-between align-center py-3 px-4 font-weight-normal",attrs:{"to":_vm.allEventsRoute}},[_c('div',{staticClass:"footer-text"},[_vm._v(" "+_vm._s(_vm.$t('viewAllActivity'))+" ")]),_c('v-icon',[_vm._v("chevron_right")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }