<template>
  <v-card
    v-bind="$attrs"
    outlined
    rounded="lg"
    class="cy-recent-events d-flex flex-column">
    <v-card-title
      v-if="showCardTitle"
      class="py-2"
      data-cy="card-title">
      <v-row
        no-gutters
        align="center"
        justify="space-between">
        <v-col
          cols="auto"
          class="cy-recent-events__headline wrap-column">
          {{ $t('recentActivity') }}
        </v-col>
        <v-col
          cols="auto"
          class="cy-recent-events__time-filter font-weight-bold text-no-wrap wrap-column text-center">
          {{ $tc('forms.field.timeRangeLastDays', 7, { days: 7 }) }}
        </v-col>
        <v-col
          cols="auto"
          class="text-right wrap-column">
          <CyMenu
            max-height="340"
            offset-y
            left
            class="text-capitalize">
            <template #activator="{ attrs, on }">
              <CyButton
                v-bind="attrs"
                theme="primary"
                variant="tertiary"
                sm
                icon="mdi-filter"
                class="ml-0 pl-2 pr-0 mr-n3 text-capitalize"
                v-on="on">
                {{ $t('severities.severity') }}: {{ activeSeverityFilterTranslation }}
                <v-icon
                  dense
                  class="ml-1">
                  arrow_drop_down
                </v-icon>
              </CyButton>
            </template>
            <v-list>
              <v-list-item-group
                :value="_.find(eventSeverityFilterOptions, { isActive: true })"
                mandatory>
                <v-list-item
                  v-for="(item, index) in eventSeverityFilterOptions"
                  :key="index"
                  :value="item"
                  @click="item.action">
                  <v-list-item-title class="text-capitalize">
                    {{ item.label }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </CyMenu>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider v-if="showCardTitle"/>
    <v-card-text
      :class="[
        'cy-recent-events__timeline-viewport cy-scrollbars--always-show d-flex justify-center height-100 flex-grow-1 py-0',
        { 'no-card-title': !showCardTitle },
      ]">
      <v-progress-circular
        v-if="$attrs.loading"
        indeterminate
        color="secondary"
        class="my-10"
        data-cy="loading-spinner"/>
      <div
        v-else-if="!events.length"
        data-cy="empty-state"
        class="cy-event-empty-state text-center py-6">
        <div class="cy-event-empty-state__heading h5">
          {{ $t('events.noEventsFound') }}
        </div>
        <div class="text-body mt-2">
          {{ $t('events.tryDifferent') }}
        </div>
      </div>
      <CyEventsTimeline
        v-else
        :events="events"
        :options="eventProps"
        :lazy-options="$static.timelineItemLazyOptions"
        :show-tags="false"
        hide-default-footer
        iterator-class="py-2"
        class="width-100"
        data-cy="events-timeline">
        <template #tag="{ tag }">
          <router-link
            :to="{
              name: 'events',
              params: { orgCanonical },
              query: { [`${tag.key}[in]`]: tag.value },
            }"
            custom
            #default="{ navigate, href }">
            <CyTag
              :variant="tag.variant"
              :label="tag.key"
              small
              :href="href"
              @click="navigate">
              {{ tag.content }}
            </CyTag>
          </router-link>
        </template>
      </CyEventsTimeline>
    </v-card-text>
    <v-divider/>
    <v-card-title
      v-if="allEventsRoute"
      class="cy-recent-events__footer pa-0">
      <router-link
        :to="allEventsRoute"
        class="width-100 d-flex justify-space-between align-center py-3 px-4 font-weight-normal">
        <div class="footer-text">
          {{ $t('viewAllActivity') }}
        </div>
        <v-icon>chevron_right</v-icon>
      </router-link>
    </v-card-title>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import CyEventsTimeline from '@/components/events/timeline.vue'

const severityQueryParam = 'severity[in]'
const severities = ['info', 'warn', 'err', 'crit']

export default {
  name: 'CyEventsRecentActivityCard',
  components: {
    CyEventsTimeline,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    allEventsRoute: {
      type: Object,
      validator: (value) => _.has(value, 'name'),
      default: () => {},
    },
    eventFilters: {
      type: Object,
      required: true,
    },
    eventProps: {
      type: Object,
      default: () => ({
        itemsPerPage: 50,
        sortBy: ['timestamp'],
        sortDesc: [true],
      }),
    },
    showCardTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    $static () {
      return {
        timelineItemLazyOptions: {
          root: document.querySelector('.cy-recent-events__timeline-viewport'),
          threshold: 0.5,
        },
      }
    },
    activeSeverityFilter () {
      return this.eventFilters[severityQueryParam]
    },
    activeSeverityFilterTranslation () {
      return this.activeSeverityFilter
        ? this.$t(`severities.${this.activeSeverityFilter}`)
        : this.$t('all')
    },
    eventSeverityFilterOptions () {
      return _.transform(severities, (result, severity) => {
        result.push({
          label: this.$t(`severities.${severity}`),
          action: () => {
            this.$emit('update:eventFilters',
              {
                ...this.eventFilters,
                [severityQueryParam]: severity,
              })
          },
          isActive: severity === this.activeSeverityFilter,
        })
      }, [{
        label: this.$t('all'),
        action: () => {
          this.REMOVE_DATA_TABLE_FILTER({
            name: this.$route.name,
            filterType: severityQueryParam,
            filterValue: this.activeSeverityFilter,
          })
          this.$emit('update:eventFilters', {
            ...this.eventFilters,
            [severityQueryParam]: undefined,
          })
        },
        isActive: this.activeSeverityFilter === undefined,
      }])
    },
  },
  methods: {
    ...mapActions('layout', [
      'REMOVE_DATA_TABLE_FILTER',
    ]),
  },
  i18n: {
    messages: {
      en: {
        recentActivity: 'Recent activity',
        viewAllActivity: 'View all activity',
      },
      es: {
        recentActivity: 'Actividad recientes',
        viewAllActivity: 'Ver toda la actividad',
      },
      fr: {
        recentActivity: 'Activité récente',
        viewAllActivity: `Voir toute l'activité`,
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$events-section-header-heights: ( en: 44px, es: 64px, fr: 64px );

.cy-recent-events {
  &__headline {
    font-size: 14px;
    font-weight: $font-weight-bolder;
    line-height: 100%;
  }

  &__time-filter {
    font-size: $font-size-sm;
    line-height: $line-height-default;
  }

  &__timeline-viewport {
    @each $lang, $header-height in $events-section-header-heights {
      [aria-locale="#{$lang}"] & {
        max-height: calc(100% - #{$header-height} - 48px);
      }
    }

    &.no-card-title {
      max-height: calc(100% - 48px);
    }
  }

  &__footer {
    font-size: $font-size-default;
    line-height: $line-height-default;

    a {
      &,
      &:hover,
      &:focus {
        color: cy-get-color("grey", "dark-2");
      }
    }
  }
}
</style>
